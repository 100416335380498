const menuData = [
    {
        enCategory: "Antipasti",
        roCategory: "Antipasti",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            // {
            //     "id": 1,
            //     "enName": "Gravlax a freddo di salmone",
            //     "roName": "Gravlax a freddo di salmone",
            //     "enDescription": "Salmon cured in salt, celery cream with baked apple, marinated cucumber, pickled shallot, cress salad with sesame oil, lemon caviar, apple chips, edamame cream ( 250g )",
            //     "roDescription": "Somon maturat în sare, cremă de țelină cu măr copt, castravete marinat, ceapă charlotte murată, salată de cress cu ulei de susan, caviar de lămâie, chipsuri de măr, cremă de edamame ( 250g )",
            //     "price": "65 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Gravlax a freddo di salmone.jpg"
            // },
            {
                "id": 2,
                "enName": "Vitello tonnato",
                "roName": "Vitello tonnato",
                "enDescription": "Black angus U.S. beef neck slow cooked for 12 hours, tuna foam, confit cherry tomatoes, pickled shallots, capers, cress salad ( 180g )",
                "roDescription": "Ceafă de vită black angus U.S. gătită lent 12 ore, spumă de ton, roșii piccadilly confit, ceapă chalot murată, capere, salată de cress ( 180g )",
                "price": "62 lei",
                "nutrition": "",
                "image": "/img/preparate/Vitello tonnato.jpg"
            },
            {
                "id": 3,
                "enName": "Cozze alla marinara",
                "roName": "Cozze alla marinara",
                "enDescription": "Mussels sautéed in butter sauce with white wine, garlic, chili pepper and parsley 450g",
                "roDescription": "Midii sotate în sos de unt cu vin alb, usturoi, ardei iute și pătrunjel ( 450g )",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/Cozze alla taranntina 450 g.jpg"
            },
            {
                "id": 4,
                "enName": "Cozze alla marinara",
                "roName": "Cozze alla marinara",
                "enDescription": "Mussels sautéed in butter sauce with white wine, garlic, chili pepper and parsley 450g",
                "roDescription": "Midii sotate în sos de unt cu vin alb, usturoi, ardei iute și pătrunjel ( 900g )",
                "price": "98 lei",
                "nutrition": "",
                "image": "/img/preparate/Cozze alla marinara 900 g.jpg"
            },
            {
                "id": 5,
                "enName": "Cozze alla tarantina",
                "roName": "Cozze alla tarantina",
                "enDescription": "Mussels sautéed in tomato sauce with herbs and wine, garlic, chili pepper and parsley 900g",
                "roDescription": "Midii sotate în sos de roșii cu ierburi și vin, usturoi, ardei iute și pătrunjel ( 450g )",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/Cozze alla taranntina 450 g.jpg"
            },
            {
                "id": 6,
                "enName": "Cozze alla tarantina",
                "roName": "Cozze alla tarantina",
                "enDescription": "Mussels sautéed in tomato sauce with herbs and wine, garlic, chili pepper and parsley 900g",
                "roDescription": "Midii sotate în sos de roșii cu ierburi și vin, usturoi, ardei iute și pătrunjel ( 900g )",
                "price": "98 lei",
                "nutrition": "",
                "image": "/img/preparate/cozze alla tarantina 900 g.jpg"
            },
            {
                "id": 7,
                "enName": "Gamberi al Forno",
                "roName": "Gamberi al Forno",
                "enDescription": "Black tiger shrimp flambéed with cognac and crustacean bisque",
                "roDescription": "Creveți Black tiger, flambați în coniac și bisque de crustacee",
                "price": "85 lei",
                "nutrition": "",
                "image": "/img/preparate/Gamberi al forno.jpg"
            },
            // {
            //     "id": 8,
            //     "enName": "Saint Jacques (capesante)",
            //     "roName": "Saint Jacques (capesante)",
            //     "enDescription": "Saint Jacques scallops toasted in butter and lime, sautéed spinach with confit tomatoes, Parmesan Reggiano shavings, vegetable ash",
            //     "roDescription": "Scoici Saint Jacques tostate în unt și lime, spanac sotat cu roșii confit, așchii de Parmigiano Reggiano, cenușă de legume",
            //     "price": "95 lei",
            //     "nutrition": "",
            //     "image": ""
            // },
            // {
            //     "id": 9,
            //     "enName": "Carpaccio di Polpo",
            //     "roName": "Carpaccio di Polpo",
            //     "enDescription": "Octopus carpaccio with garlic, herbs and citrus zest, fennel salad with celery leaf, chimichurri",
            //     "roDescription": "Carpaccio de caracatiță cu usturoi, ierburi și zest de citrice, salată de fenicul cu frunză de apio, chimichurry",
            //     "price": "68 lei",
            //     "nutrition": "",
            //     "image": ""
            // },
            {
                "id": 10,
                "enName": "Tartare di tonno",
                "roName": "Tartare di tonno",
                "enDescription": "Red tuna with olive oil, pistachio and lime zest, seaweed tempura chips, fennel salad with lime, dill and cress",
                "roDescription": "Ton roșu cu ulei de măsline, fistic și zest de lime, chips de alge tempura, salată de fenicul cu lime, mărar și cress",
                "price": "75 lei",
                "nutrition": "",
                "image": "/img/preparate/Tartare di tonno.jpg"
            },
            {
                "id": 11,
                "enName": "Caprese",
                "roName": "Caprese",
                "enDescription": "Mozzarella di Bufala DOP, San Marzano tomatoes, confit tomatoes, stracciatella di burrata, basil oil, fresh basil",
                "roDescription": "Mozzarella di buffala DOP, roșii San Marzano, roșii confit, stracciatella di burrata, ulei de busuioc, busuioc proaspăt;",
                "price": "48 lei",
                "nutrition": "",
                "image": "/img/preparate/Caprese.jpg"
            },
            {
                "id": 12,
                "enName": "Taglieri di salumi e formaggi italiani",
                "roName": "Taglieri di salumi e formaggi italiani",
                "enDescription": "Homemade porchetta, napoli salami, Spianata picantă, prosciutto crudo, Parmigiano Reggiano, Pecorino Romano, Gorgonzola DOP, roasted walnuts and cashews, homemade breadsticks, olive oil with aromatic herbs - recommended for two persons",
                "roDescription": "Porcheta făcută în casă, salam Napoli, Spianata picantă, prosciutto crudo, Parmigiano Reggiano, pecorino romano, Gorgonzola DOP, nuci și caju prăjit, grisine făcute în casă, ulei de măsline cu ierburi aromatice - recomandat pentru două persoane",
                "price": "75 lei",
                "nutrition": "",
                "image": "/img/preparate/Taglieri di salumi e formaggi Italiani.jpg"
            }

        ]
    },
    {
        enCategory: "Primi piatti",
        roCategory: "Primi piatti",
        enSubCategory: "Pasta",
        roSubCategory: "Paste",
        dishes: [
            {
                "id": 13,
                "enName": "Linguine al limone",
                "roName": "Linguine al limone",
                "enDescription": "Homemade pasta, white sauce with lemon, butter, Parmigiano Reggiano, lemon caviar and lime zest",
                "roDescription": "Paste făcute în casă, sos alb cu lămâie, unt, Parmigiano Reggiano, caviar de lămâie și zest de lime",
                "price": "39 lei",
                "nutrition": "",
                "image": "/img/preparate/Linguine al limone.jpg"
            },
            // {
            //     "id": 14,
            //     "enName": "Raviolone alla 'nduja",
            //     "roName": "Raviolone alla 'nduja",
            //     "enDescription": "Homemade ravioli filled with beef and sausage ragu, ricotta and 'nduja, sautéed in butter sauce with Parmigiano Reggiano and sage, rocket salad with Parmesan and parsley oil",
            //     "roDescription": "Raviolone făcută în casă umplută cu ragu de vită și salsicia, ricotta și 'nduja, sotat în sos de unt cu Parmigiano Reggiano cu salvie, salată de rucola cu parmezan și ulei de pătrunjel",
            //     "price": "52 lei",
            //     "nutrition": "",
            //     "image": ""
            // },
            {
                "id": 15,
                "enName": "Spaghetti A.O.P",
                "roName": "Spaghetti A.O.P",
                "enDescription": "Homemade pasta cooked in olive oil, garlic, parsley, chili pepper and pepperoncino flakes",
                "roDescription": "Paste făcute în casă gătite în ulei de măsline, usturoi, pătrunjel, ardei iute și fulgi de peperoncino",
                "price": "44 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghetti AOP 1.jpg"
            },
            {
                "id": 16,
                "enName": "Spaghetti A.O.P. con gamberi",
                "roName": "Spaghetti A.O.P. con gamberi",
                "enDescription": "Homemade pasta cooked in olive oil, garlic, parsley, chili pepper and pepperoncino flakes, prawns",
                "roDescription": "Paste făcute în casă gătite în ulei de măsline, usturoi, pătrunjel, ardei iute și fulgi de peperoncino, creveți",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghetti AOP CON GAMBERI.jpg"
            },
            {
                "id": 17,
                "enName": "Spaghetti Carbonara",
                "roName": "Spaghetti Carbonara",
                "enDescription": "Homemade pasta, guanciale (cured pork cheek), Parmigiano Reggiano, peperoncino Romano, eggs",
                "roDescription": "Paste făcute în casă, guanciale rumenit, Parmigiano Reggiano, peperoncino Romano, ouă",
                "price": "55 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghetti Carbonara.jpg"
            },
            {
                "id": 18,
                "enName": "Spaghette Amatriciana",
                "roName": "Spaghette Amatriciana",
                "enDescription": "Homemade pasta cooked in a flavorful tomato sauce, guanciale (cured pork cheek), Parmigiano Reggiano shavings, toasted datterini tomatoes",
                "roDescription": "Paste de casă gătite în sos de roșii aromatizat, guanciale rumenit, scalii de Parmigiano Reggiano, datterini tostate",
                "price": "52 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghette Amatriciana.jpg"
            },
            {
                "id": 19,
                "enName": "Spaghetti alla Nerano",
                "roName": "Spaghetti alla Nerano",
                "enDescription": "Homemade pasta, butter sauce with Parmigiano Reggiano and crispy zucchini",
                "roDescription": "Paste făcute în casă, sos de unt cu Parmigiano Reggiano și zucchine crocant",
                "price": "49 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghetti alla Nerano.jpg"
            },
            {
                "id": 20,
                "enName": "Radiatori al Pomodoro",
                "roName": "Radiatori al Pomodoro",
                "enDescription": "Homemade pasta with flavored tomato sauce, fresh basil, toasted datterini tomatoes",
                "roDescription": "Paste făcute în casă cu sos de roșii aromatizat, busuioc proaspăt, datterini tostate",
                "price": "39 lei",
                "nutrition": "",
                "image": "/img/preparate/Raddiotori al pomodoro1.jpg"
            },
            {
                "id": 21,
                "enName": "Linguine Caccio e Pepe",
                "roName": "Linguine Caccio e Pepe",
                "enDescription": "Homemade pasta with Parmigiano Reggiano sauce, pecorino Romano foam, sage and five types of pepper",
                "roDescription": "Paste făcute în casă cu sos de Parmigiano Reggiano, spumă de pecorino Romano, salvie și cinci tipuri de piper",
                "price": "49 lei",
                "nutrition": "",
                "image": "/img/preparate/Linguine Cacio e pepe.jpg"
            },
            {
                "id": 22,
                "enName": "Linguine al tartufo",
                "roName": "Linguine al tartufo",
                "enDescription": "Homemade pasta, butter sauce with truffles, Parmigiano Reggiano",
                "roDescription": "Paste făcute în casă, sos de unt cu trufe, Parmigiano Reggiano",
                "price": "72 lei",
                "nutrition": "",
                "image": "/img/preparate/Tagliatelle al tartufo9.jpg"
            },
            {
                "id": 23,
                "enName": "Strozzapretti con straccetti di manzo",
                "roName": "Strozzapretti con straccetti di manzo",
                "enDescription": "Homemade pasta, U.S. black Angus beef, demi-glace sauce with butter and green asparagus, Parmigiano Reggiano shavings",
                "roDescription": "Paste făcută în casă, vită black Angus US, sos demi-glace cu unt și sparanghel verde, scalii Parmigiano Reggiano;",
                "price": "59 lei",
                "nutrition": "",
                "image": "/img/preparate/Strozapretti con stracetti di manzo5.jpg"
            },
            {
                "id": 24,
                "enName": "Spaghetti all' assasina",
                "roName": "Spaghetti all' assasina",
                "enDescription": "Homemade pasta burnt in tomato paste and 'nduja, burrata cream, vegetable ash and rosemary",
                "roDescription": "Paste făcute în casă, arse în pastă de tomate și 'nduja, cremă di burrata, cenușă de legume și rozmarin",
                "price": "58 lei",
                "nutrition": "",
                "image": "/img/preparate/Spaghetti All'assasina.jpg"
            },
            {
                "id": 25,
                "enName": "Linguine allo Scoglio",
                "roName": "Linguine allo Scoglio",
                "enDescription": "Homemade pasta cooked in a sauce of tomatoes, wine, and shrimp bisque, with mussels, squid, prawns, clams, and Black Tiger shrimp",
                "roDescription": "Paste făcute în casă, gătite în sos de roșii, vin și bisque de creveți, midii, calamar, creveți, vongole și Black Tiger",
                "price": "89 lei",
                "nutrition": "",
                "image": "/img/preparate/Linguine allo Scoglio.jpg"
            },
            {
                "id": 26,
                "enName": "Radiatorri con gamberi",
                "roName": "Radiatorri con gamberi",
                "enDescription": "Homemade pasta in a butter sauce with tomatoes, shrimp, baby spinach, and Parmesan shavings",
                "roDescription": "Paste făcute în casă, gătite în sos de unt cu roșii, creveți, baby spanac și scalii de parmezan",
                "price": "69 lei",
                "nutrition": "",
                "image": "/img/preparate/Radiattori con gamberi5.jpg"
            },
            {
                "id": 27,
                "enName": "Linguine gamberi e asparagi",
                "roName": "Linguine gamberi e asparagi",
                "enDescription": "Homemade pasta in a butter sauce, demi-glace and crustacean bisque, shrimp, fresh asparagus, and toasted datterini tomatoes",
                "roDescription": "Paste făcute în casă în sos de unt, demi-glace și bisque de crustacee, creveți, sparanghel proaspăt și datterini tostate",
                "price": "72 lei",
                "nutrition": "",
                "image": "/img/preparate/Tagloatelle gamberi e asparagi5.jpg"
            },
            {
                "id": 28,
                "enName": "Lasagna alla bolognese",
                "roName": "Lasagna alla bolognese",
                "enDescription": "Homemade lasagna with beef and sausage ragu, mozzarella di buffala DOP, Parmigiano Reggiano and Pecorino Romano foam, basil oil, and fried pasta chips",
                "roDescription": "Lasagna făcută în casă, cu ragu de vită și salsiccia, mozzarella di bufala DOP, spumă de Parmigiano Reggiano și pecorino romano, ulei de busuioc și chips de paste prăjite",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/Lasagna alla bolognese.jpg"
            }
        ]
    },
    {
        enCategory: "Primi piatti",
        roCategory: "Primi piatti",
        enSubCategory: "Insalata",
        roSubCategory: "Insalata",
        dishes: [
            {
                "id": 29,
                "enName": "Insalata burrata e pomodorini",
                "roName": "Insalata burrata e pomodorini",
                "enDescription": "Gourmet salad greens, confit tomatoes, pomegranate-disaronno dressing, toasted flaxseeds, and basil oil (280 g)",
                "roDescription": "Frunze de salată gourmet, roșii confit, dressing disarono cu rodie, semințe de in tostate și ulei de busuioc (280 g)",
                "price": "55 lei",
                "nutrition": "",
                "image": "/img/preparate/Insalata burrata e pomodorini.jpg"
            },
            {
                "id": 30,
                "enName": "Insalata di Pollo",
                "roName": "Insalata di Pollo",
                "enDescription": "Sous-vide grilled chicken breast, romaine salad with tuna dressing, Dijon sauce, homemade chabatta bread toasted in butter with herbs and garlic, toasted pumpkin seeds, crispy smoked pancetta (340 g)",
                "roDescription": "Piept de pui sous-vide rumenit pe grătar, salată romană cu dressing de ton, sos dijon, ciabatta făcută în casă rumenită în unt cu ierburi și usturoi, semințe de dovleac tostate, pancetta affumicata crocantă (340 g)",
                "price": "59 lei",
                "nutrition": "",
                "image": "/img/preparate/Insalata di pollo.jpg"
            },
            {
                "id": 31,
                "enName": "Insalata al Tonno Fresco",
                "roName": "Insalata al Tonno Fresco",
                "enDescription": "Pistachio-crusted fresh tuna, pomegranate-disaronno dressing, rocket salad with confit tomatoes (230 g)",
                "roDescription": "Ton roșu în crustă de fistic, dressing de dissarono cu rodie, salată de rucola cu roșii confit (230 g)",
                "price": "79 lei",
                "nutrition": "",
                "image": "/img/preparate/Insalata al tonno fresco.jpg"
            },
            // {
            //     "id": 32,
            //     "enName": "Insalata con Gamberi",
            //     "roName": "Insalata con Gamberi",
            //     "enDescription": "Grilled shrimp, baby spinach salad with starfruit, marinated cucumber with dill, lime, and celery, green apple, corn shoots, toasted pumpkin seeds, and edamame beans (320 g)",
            //     "roDescription": "Creveți pe jar, salată de baby spanac cu carambola, castravete marinat cu mărar, lime și apio, măr verde, vlăstar de porumb, semințe de dovleac tostate și boabe de edamame (320 g)",
            //     "price": "69 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Insalata con Gamberi.jpg"
            // }
        ]
    },
    {
        enCategory: "Primi piatti",
        roCategory: "Primi piatti",
        enSubCategory: "Risotto",
        roSubCategory: "Risotto",
        dishes: [
            {
                "id": 33,
                "enName": "Risotto Melanzana e Burrata",
                "roName": "Risotto Melanzana e Burrata",
                "enDescription": "Carnaroli rice cooked in a roasted eggplant cream, Parmigiano Reggiano, and butter, DOP burrata, toasted flaxseeds, and cress salad in basil oil (400 g)",
                "roDescription": "Orez Carnaroli gătit în cremă de vânătă coaptă, Parmigiano Reggiano și unt, burrata DOP, semințe de in tostate și salată de cress în ulei cu busuioc (400 g)",
                "price": "62 lei",
                "nutrition": "",
                "image": "/img/preparate/Risotto melanzane e burrata.jpg"
            },
            // {
            //     "id": 34,
            //     "enName": "Risotto Caccio e Peppe",
            //     "roName": "Risotto Caccio e Peppe",
            //     "enDescription": "Carnaroli rice cooked in butter and Parmigiano Reggiano, crispy sage, pecorino Romano, 5 types of pepper (280 g)",
            //     "roDescription": "Orez Carnaroli gătit în unt și Parmigiano Reggiano, salvie crocantă, pecorino romano, 5 tipuri de piper (280 g)",
            //     "price": "52 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Risotto cacio e pepe .jpg"
            // },
            {
                "id": 35,
                "enName": "Risotto alla pescatore",
                "roName": "Risotto alla pescatore",
                "enDescription": "Carnaroli rice cooked in butter and crustacean bisque, Parmigiano Reggiano, wine and shrimp bisque, mussels, squid, prawns, clams, and Black Tiger shrimp (280 g)",
                "roDescription": "Orez Carnaroli gătit în unt și bisque de crustacee, Parmigiano Reggiano, vin și bisque de creveți, midii, calamar, creveți, vongole și Black Tiger (280 g)",
                "price": "92 lei",
                "nutrition": "",
                "image": "/img/preparate/Risotto alla pescatore67.jpg"
            },
            {
                "id": 36,
                "enName": "Risotto al Tartufo",
                "roName": "Risotto al Tartufo",
                "enDescription": "Carnaroli rice cooked in butter, wine, and Parmigiano Reggiano, truffles, and parsley oil (280 g)",
                "roDescription": "Orez Carnaroli gătit în unt, vin și Parmigiano Reggiano, trufe și ulei de pătrunjel (280 g)",
                "price": "76 lei",
                "nutrition": "",
                "image": "/img/preparate/Risotto al tartufo2.jpg"
            }
        ]
    },
    {
        enCategory: "Il secondo",
        roCategory: "Il secondo",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            {
                "id": 37,
                "enName": "Parmigiana di Melanzane",
                "roName": "Parmigiana di Melanzane",
                "enDescription": "Baked eggplant slices, cooked in a seasoned tomato sauce and mozzarella fior di latte, béchamel sauce, crispy eggplant fan (400 g)",
                "roDescription": "Vinete coapte, gătite în sos de roșii aromatizat și mozzarella fior di latte, sos bechamel, fân de vinete crocante (400 g)",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/Parmigiana di melanzane.jpg"
            },
            {
                "id": 38,
                "enName": "Bistecca di melanzana al forno",
                "roName": "Bistecca di melanzana al forno",
                "enDescription": "Grilled eggplant steak, Parmigiano Reggiano and Pecorino Romano foam, selection of confit gourmet tomatoes, vegetable ash, and edamame cream (320 g)",
                "roDescription": "Steak de vânătă pe jar, spumă de Parmigiano Reggiano cu pecorino Romano, confit de selecție de roșii gourmet confiate, cenușă de legume și cremă de edamame (320 g)",
                "price": "69 lei",
                "nutrition": "",
                "image": "/img/preparate/Bistecca di melanzane al forno.jpg"
            },
            {
                "id": 39,
                "enName": "Galletto al forno",
                "roName": "Galletto al forno",
                "enDescription": "Mountain rooster cooked for 12 hours sous-vide, caramelized carrots, parsnip batons in teriyaki glaze and roasted peanuts, celery puree, asparagus, and demi-glace sauce (500 g)",
                "roDescription": "Cocoşel de munte gătit 12 ore la sous-vide, morcov caramelizat, batoane de păstârnac in glace de teryaky, piure de țelină, sparanghel și sos demi-glace (500 g)",
                "price": "78 lei",
                "nutrition": "",
                "image": "/img/preparate/Galletto al forno.jpg"
            },
            {
                "id": 40,
                "enName": "Bistecca di maialino",
                "roName": "Bistecca di maialino",
                "enDescription": "Grilled suckling pig steak, roasted potatoes with guanciale and chili pepper, broccolini sautéed in butter with garlic, king oyster mushrooms on the grill, and mushroom and truffle demi-glace (300 g)",
                "roDescription": "Steak din purceluș de lapte la jar, cartofi rumeniți cu guanciale și ardei iute, broccolini sotat în unt cu usturoi, king oyster la grill și demi-glace cu ciuperci shimeji (300 g)",
                "price": "115 lei",
                "nutrition": "",
                "image": "/img/preparate/Bistecca di maialino.jpg"
            },
            {
                "id": 41,
                "enName": "Ossobuco alla Milanese",
                "roName": "Ossobuco alla Milanese",
                "enDescription": "Slowly cooked veal shank, caramelized carrots, saffron risotto, and Parmigiano Reggiano (450 g)",
                "roDescription": "Rasol de vită gătit lent, morcov caramelizat, risottocu şofran și Parmigiano Reggiano (450 g)",
                "price": "96 lei",
                "nutrition": "",
                "image": "/img/preparate/Ossobucco alla milanese.jpg"
            },
            {
                "id": 42,
                "enName": "Branzino in crosta di sale",
                "roName": "Branzino in crosta di sale",
                "enDescription": "Sea bass cooked in a salt crust with citrus and herbs (100 g per person)",
                "roDescription": "Lup de mare gătit in crustă de sare cu citrice și ierburi (100 g per person)",
                "price": "35 lei/100 g",
                "nutrition": "",
                "image": "/img/preparate/Branzino.jpg"
            },
            {
                "id": 43,
                "enName": "Bistecca di tonno",
                "roName": "Bistecca di tonno",
                "enDescription": "Pistachio-crusted red tuna, selection of white and green asparagus cooked in butter, citrus fruits and chives, pomegranate sauce with Disaronno, Maldon salt (350 g)",
                "roDescription": "Ton roșu în crustă de fistic, selecție de sparanghel verde gătit în unt cu citrice și chives, sos de rodie cu Disaronno, sare Maldon (350 g)",
                "price": "125 lei",
                "nutrition": "",
                "image": "/img/preparate/Bistecca di tonno.jpg"
            },
            {
                "id": 44,
                "enName": "Entrecote di manzo",
                "roName": "Entrecote di manzo",
                "enDescription": "Grilled Argentinian Black Angus beef ribeye steak, baby carrots caramelized, roasted celery root puree, potato terrine with butter and aromatic herbs, truffle demi-glace sauce, edamame cream (400 g)",
                "roDescription": "Antricot de vită Black Angus de Argentina la grătar, baby morcov caramelizat, piureu de ţelină coaptă, terină din foi de cartofi cu unt și ierburi aromatice, sos demi-glace cu trufe, cremă de edamame (400 g)",
                "price": "145 lei",
                "nutrition": "",
                "image": "/img/preparate/Entrecote di manzo4.jpg"
            },
            {
                "id": 45,
                "enName": "Fantasia di pesce al sugo mediterraneo",
                "roName": "Fantasia di pesce al sugo mediterraneo",
                "enDescription": "Mix of seafood (shrimp, mussels, calamari, clams, and Black Tiger shrimp) cooked in a seasoned tomato sauce, crustacean bisque, butter, parmesan, garlic, homemade ciabatta with herbs and garlic (550 g)",
                "roDescription": "Mix de fructe de mare (creveți, midii, calamar, vongole și Black Tiger) gătite în sos de roșii aromatizat , bisque de crustacee, unt, parmezan, usturoi, ciabatta de casă, cu ierburi și usturoi (550 g)",
                "price": "92 lei",
                "nutrition": "",
                "image": "/img/preparate/Fantasia di pesce ai sugo 4.jpg"
            },
            {
                "id": 46,
                "enName": "Polpo alla griglia",
                "roName": "Polpo alla griglia",
                "enDescription": "Slow-cooked octopus grilled, baby potatoes roasted with rosemary, toasted artichokes, confit tomatoes, broccolini sautéed in butter and garlic, and Maldon salt (360 g)",
                "roDescription": "Caracatiță gătită lent pe jar, baby cartofi rumeniți pe rozmarin, anghinare tostată, roșii confit, broccolini sotate în unt și usturoi și sare maldon (360 g)",
                "price": "115 lei",
                "nutrition": "",
                "image": "/img/preparate/Polpo alla griglia.jpg"
            },
            {
                "id": 47,
                "enName": "Porchetta",
                "roName": "Porchetta",
                "enDescription": "Porchetta, baby potatoes roasted with rosemary, demi-glace sauce, and Maldon salt (400 g)",
                "roDescription": "Porchetta, baby cartofi rumeniți cu rozmarin, sos demi-glace și sare de maldon (400 g)",
                "price": "115 lei",
                "nutrition": "",
                "image": "/img/preparate/Porchetta.jpg"
            },
            {
                "id": 48,
                "enName": "Tagliata di manzo",
                "roName": "Tagliata di manzo",
                "enDescription": "Argentinian Black Angus beef cooked for 24 hours, arugula salad with toasted cherry tomatoes and Parmigiano Reggiano shavings (360 g)",
                "roDescription": "Antricot de vită Black Angus Argentina gătită 24 de ore, salată de rucola cu roșii cherry tostate și scalie de Parmigiano Reggiano (360 g)",
                "price": "139 lei",
                "nutrition": "",
                "image": "/img/preparate/Tagliata di manzo.jpg"
            }
        ]
    },
    {
        enCategory: "Contorni",
        roCategory: "Contorni",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            {
                "id": 49,
                "enName": "Peperoni arrosto",
                "roName": "Peperoni arrosto",
                "enDescription": "Roasted bell peppers, Modena vinegar cream, toasted flaxseeds, cheese (200 g)",
                "roDescription": "Ardei copt la jar, cremă de oțet di Modena, semințe de in tostate, chives (200 g)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Peperoni arrosto.jpg"
            },
            {
                "id": 50,
                "enName": "Asparagi",
                "roName": "Asparagi",
                "enDescription": "Sautéed green asparagus in butter and garlic, Maldon salt (120 g)",
                "roDescription": "Sparanghel verde sotat în unt și usturoi, sare maldon (120 g)",
                "price": "29 lei",
                "nutrition": "",
                "image": "/img/preparate/Asparagi.jpg"
            },
            {
                "id": 51,
                "enName": "Patate anna (pommes anna)",
                "roName": "Patate anna (pommes anna)",
                "enDescription": "Potato gratin with butter, aromatic herbs, and Parmigiano Reggiano (200 g)",
                "roDescription": "Terina din foi de cartofi cu unt, ierburi aromatice și Parmigiano Reggiano (200 g)",
                "price": "28 lei",
                "nutrition": "",
                "image": "/img/preparate/Patate Anna.jpg"
            },
            {
                "id": 52,
                "enName": "Pure di sedano rapa",
                "roName": "Pure di sedano rapa",
                "enDescription": "Mashed celery root cooked in milk and butter (200 g)",
                "roDescription": "Piure de țelină coaptă în lapte și unt (200 g)",
                "price": "22 lei",
                "nutrition": "",
                "image": "/img/preparate/Piure di sedano rapa.jpg"
            },
            {
                "id": 53,
                "enName": "Broccolino",
                "roName": "Broccolino",
                "enDescription": "Sautéed broccolini in butter and garlic, Maldon salt (150 g)",
                "roDescription": "Broccolino sotat în unt și usturoi, sare maldon (150 g)",
                "price": "32 lei",
                "nutrition": "",
                "image": "/img/preparate/Broccolino.jpg"
            },
            {
                "id": 54,
                "enName": "Insalata di rucola pomodini e Parmigiano",
                "roName": "Insalata di rucola pomodini e Parmigiano",
                "enDescription": "Rocket salad with a selection of gourmet tomatoes and Parmigiano Reggiano shavings (80 g)",
                "roDescription": "Salată de rucola cu o selecție de roșii gourmet și scalii de parmezan",
                "price": "27 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 55,
                "enName": "Insalata mista",
                "roName": "Insalata mista",
                "enDescription": "Mixed salad greens, cherry tomatoes, and datterini tomatoes",
                "roDescription": "Frunze de salată misticanza, roșii piccadilly și datterini",
                "price": "25 lei",
                "nutrition": "",
                "image": "/img/preparate/Insalata mista.jpg"
            },
            {
                "id": 56,
                "enName": "Patate al forno",
                "roName": "Patate al forno",
                "enDescription": "Roasted baby potatoes in butter and rosemary (200 g)",
                "roDescription": "Baby cartofi rumeniți în unt și rozmarin (200 g)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            // {
            //     "id": 57,
            //     "enName": "Asparagi bianchi",
            //     "roName": "Asparagi bianchi",
            //     "enDescription": "White asparagus cooked in a butter sauce with cheese and lemon (120 g)",
            //     "roDescription": "Sparanghel alb, gătit în sos de unt cu chives și lămâie (120 g)",
            //     "price": "38 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Asparagi bianchi.jpg"
            // },
            {
                "id": 58,
                "enName": "Verdure alle erbe aromatiche ",
                "roName": "Verdure alle erbe aromatiche ",
                "enDescription": "Grilled and caramelized root vegetables with butter and parsley (200 g)",
                "roDescription": "Legume rădăcinoase la grill cu unt și pătrunjel (200 g)",
                "price": "29 lei",
                "nutrition": "",
                "image": "/img/preparate/Verdure caramellatte.jpg"
            },
            {
                "id": 59,
                "enName": "Spinaci saltati",
                "roName": "Spinaci saltati",
                "enDescription": "Sautéed baby spinach with butter, garlic, and confit tomatoes (120 g)",
                "roDescription": "Sote de baby spanac cu unt, usturoi și roșii confit (120 g)",
                "price": "32 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Salsa",
        roCategory: "Salsa",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            {
                "id": 60,
                "enName": "Demi-glace",
                "roName": "Demi-glace",
                "enDescription": "",
                "roDescription": "",
                "price": "14 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 61,
                "enName": "Salsa tartufata",
                "roName": "Salsa tartufata",
                "enDescription": "",
                "roDescription": "",
                "price": "17 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 62,
                "enName": "Glassa di vino rosso",
                "roName": "Glassa di vino rosso",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 63,
                "enName": "Salsa tre pepi",
                "roName": "Salsa tre pepi",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 64,
                "enName": "Salsa di Parmigiano",
                "roName": "Salsa di Parmigiano",
                "enDescription": "",
                "roDescription": "",
                "price": "18 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 65,
                "enName": "Salsa di erbe",
                "roName": "Salsa di erbe",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 66,
                "enName": "Selezione di pane",
                "roName": "Selezione di pane",
                "enDescription": "Selection of homemade bread: rosemary focaccia, breadsticks with herb breadcrumbs, toasted ciabatta slices (200 g)",
                "roDescription": "Focaccia cu rozmarin făcută în casă, grisine în crustă de pesmet cu ierburi, felie de chiabatta prăjită (200 g)",
                "price": "25 lei",
                "nutrition": "",
                "image": ""
            },

        ]
    },
    {
        enCategory: "Pinsa Romana",
        roCategory: "Pinsa Romana",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            {
                "id": 67,
                "enName": "Margherita",
                "roName": "Margherita",
                "enDescription": "Pizza made with five types of flour, tomato sauce, mozzarella fior di latte, Burrata DOP, selection of gourmet tomatoes, olive oil (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos de roșii, mozzarella fior di latte, Burrata DOP, selecție de roșii gourmet, ulei de măsline (450 g)",
                "price": "54 lei",
                "nutrition": "",
                "image": "/img/preparate/Margherita.jpg"
            },
            {
                "id": 68,
                "enName": "Pesto e porchetta",
                "roName": "Pesto e porchetta",
                "enDescription": "Pizza made with five types of flour, mozzarella fior di latte, artisanal porchetta, pesto, Parmigiano Reggiano (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, mozzarella fior di latte, porchetta artizanala, pesto, Parmigiano Reggiano (450 g)",
                "price": "55 lei",
                "nutrition": "",
                "image": "/img/preparate/Pesto e porchetta).jpg"
            },
            {
                "id": 69,
                "enName": "Funghi e tartufo",
                "roName": "Funghi e tartufo",
                "enDescription": "Pizza made with five types of flour, white sauce, mozzarella fior di latte, mushroom mix, truffle cream (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos alb, mozzarella fior di latte, mix de ciuperci, cremă de trufe (450 g)",
                "price": "57 lei",
                "nutrition": "",
                "image": "/img/preparate/Funghi e tartufo.jpg"
            },
            {
                "id": 70,
                "enName": "Diavola",
                "roName": "Diavola",
                "enDescription": "Pizza made with five types of flour, tomato sauce, mozzarella fior di latte, spicy Spianata Romana, chili pepper (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos de roșii, mozzarella fior di latte, Spianata Romana picantă, ardei iute (450 g)",
                "price": "52 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 71,
                "enName": "All'Italiana",
                "roName": "All'Italiana",
                "enDescription": "Pizza made with five types of flour, tomato sauce, prosciutto crudo, mozzarella di buffala DOP, Parmigiano Reggiano, rocket, olive oil (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos de roșii, prosciutto crudo, mozzarella di buffala DOP, Parmigiano Reggiano, rucola, ulei de măsline (450 g)",
                "price": "65 lei",
                "nutrition": "",
                "image": "/img/preparate/All italiana.jpg"
            },
            {
                "id": 72,
                "enName": "A.O.P.",
                "roName": "A.O.P.",
                "enDescription": "Pizza made with five types of flour, tomato sauce, chimichurri sauce, Parmigiano Reggiano, olive oil, basil (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos de roșii, sos chimichurri, Parmigiano Reggiano, ulei de măsline și busuioc (450 g)",
                "price": "51 lei",
                "nutrition": "",
                "image": "/img/preparate/Aop).jpg"
            },
            {
                "id": 73,
                "enName": "La Dolce Roma",
                "roName": "La Dolce Roma",
                "enDescription": "Pizza made with five types of flour, tomato sauce, mozzarella fior di latte, Napoli salami, olive oil (450 g)",
                "roDescription": "Aluat din cinci tipuri de făină, sos de roșii, mozzarella fior di latte, salam Napoli, ulei de măsline (450 g)",
                "price": "50 lei",
                "nutrition": "",
                "image": "/img/preparate/La dolce Roma 0.jpg"
            }
        ]
    },
    {
        enCategory: "Dolce",
        roCategory: "Dolce",
        enSubCategory: "",
        roSubCategory: "",
        dishes: [
            {
                "id": 74,
                "enName": "Tiramisu' Perfetto",
                "roName": "Tiramisu' Perfetto",
                "enDescription": "Layers of ladyfingers soaked in espresso, mascarpone cream, cocoa powder, and a meringue paper (120 g)",
                "roDescription": "Sferă de mascarpone cu cafea și savoiardi însiropat, hârtie de bezea, jeleu de expresso, spumă de capuccino și pandișpan de cafea (120 g)",
                "price": "39 lei",
                "nutrition": "",
                "image": "/img/preparate/Tiramisu Perfetto.jpg"
            },
            {
                "id": 175,
                "enName": "Cannoli Siciliani al Caramello salato",
                "roName": "Cannoli Siciliani al Caramello salato",
                "enDescription": "Ricotta cream with lemon and orange zest, salted caramel, caramelized roasted almonds (60g)",
                "roDescription": "Cremă de ricotta cu coji de lămâie și portocale, caramel sărat, alune prăjite caramelizate ( 60g )",
                "price": "22 lei",
                "nutrition": "",
                "image": ""
            },
            // {
            //     "id": 75,
            //     "enName": "Sfogliatelle Napoletana",
            //     "roName": "Sfogliatelle Napoletana",
            //     "enDescription": "Homemade puff pastry filled with vanilla cream, raspberry, caramelized orange, and white chocolate truffles with roasted pistachio (120 g)",
            //     "roDescription": "Foietaj făcut în casă cu cremă de vanilie, zmeură, portocală caramelizată și trufă de ciocolată albă cu fistic prăjit (120 g)",
            //     "price": "36 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Sfogliatelle Napoletana3.jpg"
            // },
            // {
            //     "id": 76,
            //     "enName": "Maritozzo - Pistachio",
            //     "roName": "Maritozzo - Cu Fistic",
            //     "enDescription": "Milk brioche flavored with citrus zest, filled with whipped cream and pistachio glaze (120 g)",
            //     "roDescription": "Brioșă de lapte aromatizat cu zest de citrice, panna, cu glazură de fistic prăjit (120 g)",
            //     "price": "32 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/MARITOZZO FISTIC.jpg"
            // },
            // {
            //     "id": 77,
            //     "enName": "Maritozzo - Nutella",
            //     "roName": "Maritozzo - Cu Nutella",
            //     "enDescription": "Milk brioche flavored with citrus zest, filled with whipped cream, Nutella, and roasted hazelnuts (120 g)",
            //     "roDescription": "Brioșă de lapte aromatizat cu zest de citrice, panna, cu nutella și alune de pădure tostate (120 g)",
            //     "price": "32 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Maritozzo nutella.jpg"
            // },
            // {
            //     "id": 78,
            //     "enName": "Maritozzo - Plain",
            //     "roName": "Maritozzo - Simplu",
            //     "enDescription": "Milk brioche flavored with citrus zest, filled with whipped cream and dusted with powdered sugar (120 g)",
            //     "roDescription": "Brioșă de lapte aromatizat cu zest de citrice, panna, cu zahăr pudră (120 g)",
            //     "price": "32 lei",
            //     "nutrition": "",
            //     "image": "/img/preparate/Maritozo cu zahar pudra.jpg"
            // }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Aperitivo moment",
        roSubCategory: "Aperitivo moment",
        dishes: [
            {
                "id": 79,
                "enName": "ITALICUS SPRITZ",
                "roName": "ITALICUS SPRITZ",
                "enDescription": "Italicus, Prosecco, olives (180 ml)",
                "roDescription": "Italicus, Prosecco, măsline (180 ml)",
                "price": "38 lei",
                "nutrition": "",
                "image": "/img/preparate/Italicus spritz5.jpg"
            },
            {
                "id": 80,
                "enName": "APEROL SPRITZ",
                "roName": "APEROL SPRITZ",
                "enDescription": "Aperol, Prosecco, soda water (180 ml)",
                "roDescription": "Aperol, Prosecco, apa minerala (180 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": "/img/preparate/Aperol dpritz9.jpg"
            },
            {
                "id": 81,
                "enName": "AMERICANO",
                "roName": "AMERICANO",
                "enDescription": "Campari, red vermouth, soda water (180 ml)",
                "roDescription": "Campari, Vermouth rosu, apa minerala (180 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 82,
                "enName": "BICICLETA",
                "roName": "BICICLETA",
                "enDescription": "Campari, white wine, soda water (180 ml)",
                "roDescription": "Campari, Vin alb, apa minerala (180 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 83,
                "enName": "HUGO",
                "roName": "HUGO",
                "enDescription": "Prosecco, elderflower cordial, lime, mint, soda water (250 ml)",
                "roDescription": "Prosecco, soc, lime, menta, apa minerala (250 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": "/img/preparate/HUGO .jpg"
            },
            {
                "id": 84,
                "enName": "CRODINO",
                "roName": "CRODINO",
                "enDescription": "Non-alcoholic aperitif (100 ml)",
                "roDescription": "Aperitif (Fara alcool) (100 ml)",
                "price": "18 lei",
                "nutrition": "",
                "image": "/img/preparate/Crodino6.jpg"
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Gin & Tonic perfect serve",
        roSubCategory: "Gin & Tonic perfect serve",
        dishes: [
            {
                "id": 85,
                "enName": "MALFY ROSA",
                "roName": "MALFY ROSA",
                "enDescription": "Malfy Rosa Gin, 3 Cents tonic water, grapefruit (250 ml)",
                "roDescription": "Gin Malfy Rosa, apa tonica 3 cents, grapefruit (250 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": "/img/preparate/Malfy rossa 9.jpg"
            },
            {
                "id": 86,
                "enName": "MALFY ARANCIA",
                "roName": "MALFY ARANCIA",
                "enDescription": "Malfy Arancia Gin, 3 Cents tonic water, orange (250 ml)",
                "roDescription": "Gin Malfy Arancia, apa tonica 3 cents, portocale (250 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": "/img/preparate/Malfy Arrancia.jpg"
            },
            {
                "id": 87,
                "enName": "MALFY LIMONE",
                "roName": "MALFY LIMONE",
                "enDescription": "Malfy Limone Gin, 3 Cents tonic water, lemon (250 ml)",
                "roDescription": "Gin Malft Limone, apa tonica 3 cents, lămâie (250 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": "/img/preparate/Malfy limone1.jpg"
            },
            {
                "id": 88,
                "enName": "GIN MARE",
                "roName": "GIN MARE",
                "enDescription": "Gin Mare, 3 Cents tonic water, thyme (250 ml)",
                "roDescription": "Gin Mare, apa tonica 3 cents, cimbru (250 ml)",
                "price": "38 lei",
                "nutrition": "",
                "image": "/img/preparate/GIN MARE.jpg"
            },
            {
                "id": 89,
                "enName": "OLIVE",
                "roName": "OLIVE",
                "enDescription": "Olive Gin, 3 Cents tonic water, olives (250 ml)",
                "roDescription": "Gin Olive, apa tonica 3 cents, măsline (250 ml)",
                "price": "40 lei",
                "nutrition": "",
                "image": "/img/preparate/Olive.jpg"
            },
            {
                "id": 90,
                "enName": "NORDES",
                "roName": "NORDES",
                "enDescription": "Nordes Gin, 3 Cents tonic water, grapes (250 ml)",
                "roDescription": "Gin Nordes, apa tonica 3 cents, struguri (250 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Nordes gin tonic.jpg"
            },
            {
                "id": 91,
                "enName": "OPERA",
                "roName": "OPERA",
                "enDescription": "Opera Gin, 3 Cents tonic water, juniper (250 ml)",
                "roDescription": "Gin Opera, apa tonica 3 cents, ienupar (250 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": "/img/preparate/OPERA.jpg"
            },
            {
                "id": 92,
                "enName": "LONDON NR3",
                "roName": "LONDON NR3",
                "enDescription": "London No. 3 Gin, 3 Cents tonic water, grapefruit (250 ml)",
                "roDescription": "Gin London nr3, apa tonica 3 cents, grapefruit (250 ml)",
                "price": "37 lei",
                "nutrition": "",
                "image": "/img/preparate/London nr 3.jpg"
            },
            {
                "id": 93,
                "enName": "BOBY'S",
                "roName": "BOBY'S",
                "enDescription": "Boby's Gin, 3 Cents tonic water, cinnamon (250 ml)",
                "roDescription": "Gin Boby s, apa tonica 3 cents, scortisoara (250 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 94,
                "enName": "MC QUEEN & THE VIOLET FOG",
                "roName": "MC QUEEN & THE VIOLET FOG",
                "enDescription": "McQueen & The Violet Fog Gin, 3 Cents tonic water, violets (250 ml)",
                "roDescription": "Gin Mc Queen & The Violet Fog, apa tonica 3 cents, violete (250 ml)",
                "price": "38 lei",
                "nutrition": "",
                "image": "/img/preparate/MC QUEEN & THE VIOLET FOG0.jpg"
            },
            {
                "id": 95,
                "enName": "BOMBAY BRAMBLE",
                "roName": "BOMBAY BRAMBLE",
                "enDescription": "Bombay Bramble Gin, 3 Cents tonic water, raspberries (250 ml)",
                "roDescription": "Gin Bombay Bramble, apa tonica 3 cents, zmeura (250 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": "/img/preparate/Bombay bramble.jpg"
            },
            {
                "id": 96,
                "enName": "Z44 GIN",
                "roName": "Z44 GIN",
                "enDescription": "Z44 Gin, 3 Cents tonic water, mint (250 ml)",
                "roDescription": "Gin Z44, apa tonica 3 cents, menta (250 ml)",
                "price": "39 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Vermouth & tonic perfect serve",
        roSubCategory: "Vermouth & tonic perfect serve",
        dishes: [
            {
                "id": 97,
                "enName": "CARPANO ANTICA FORMULA",
                "roName": "CARPANO ANTICA FORMULA",
                "enDescription": "Carpano Antica Formula Vermouth, 3 Cents tonic water, orange (180 ml)",
                "roDescription": "Vermouth Carpano Antica Formula, apa tonica 3 cents, portocala (180 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Carpano antica formula, petrono rojo, .jpg"
            },
            {
                "id": 98,
                "enName": "PETRONI ALB",
                "roName": "PETRONI ALB",
                "enDescription": "Petroni White Vermouth, 3 Cents tonic water, lemon (180 ml)",
                "roDescription": "Vermouth Petroni alb, apa tonica 3 cents, lămâie (180 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 99,
                "enName": "PETRONI ROJO",
                "roName": "PETRONI ROJO",
                "enDescription": "Petroni Rojo Vermouth, 3 Cents tonic water, orange (180 ml)",
                "roDescription": "Vermouth Petroni rojo, apa tonica 3 cents, portocala (180 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": "/img/preparate/Carpano antica formula, petrono rojo, .jpg"
            },
            {
                "id": 100,
                "enName": "MARTINI RISERVA RUBINO",
                "roName": "MARTINI RISERVA RUBINO",
                "enDescription": "Martini Riserva Rubino Vermouth, 3 Cents tonic water, orange (180 ml)",
                "roDescription": "Vermouth Martini Riserva Rubino, apa tonica 3 cents, portocala (180 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 101,
                "enName": "MARTINI RISERVA AMBRATTO",
                "roName": "MARTINI RISERVA AMBRATTO",
                "enDescription": "Martini Riserva Ambratto Vermouth, 3 Cents tonic water, lemon (180 ml)",
                "roDescription": "Vermouth Martini Riserva Ambratto, apa tonica 3 cents, lămâie (180 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": "/img/preparate/Martini rizerva Ambratto6.jpg"
            },
            {
                "id": 102,
                "enName": "ALVEAR ROJO",
                "roName": "ALVEAR ROJO",
                "enDescription": "Alvear Rojo Vermouth, 3 Cents tonic water, orange (180 ml)",
                "roDescription": "Vermouth Alvear Rojo, apa tonica 3 cents, portocala (180 ml)",
                "price": "35 lei",
                "nutrition": "",
                "image": "/img/preparate/Martini riserva, Alvearo rojo 9.jpg"
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Negroni time",
        roSubCategory: "Negroni time",
        dishes: [

            {
                "id": 103,
                "enName": "CLASIC NEGRONI",
                "roName": "CLASIC NEGRONI",
                "enDescription": "Gin London No. 3, Petroni Rojo Vermouth, Campari (120 ml)",
                "roDescription": "Gin London nr3, Vermouth Petroni rojo, Campari (120 ml)",
                "price": "37 lei",
                "nutrition": "",
                "image": "/img/preparate/NEGRONI.jpg"
            },
            {
                "id": 104,
                "enName": "COCONUT NEGRONI",
                "roName": "COCONUT NEGRONI",
                "enDescription": "Gin Opera, Petroni Rojo Vermouth, Campari, coconut (120 ml)",
                "roDescription": "Gin Opera, Vermouth Petroni rojo, Campari, cocos (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Coconut Negroni2.jpg"
            },
            {
                "id": 105,
                "enName": "PIZZA NEGRONI",
                "roName": "PIZZA NEGRONI",
                "enDescription": "Gin Opera, Petroni Rojo Vermouth, Campari, tomato water (120 ml)",
                "roDescription": "Gin Opera, Vermouth Petroni rojo, Campari, apa de tomate (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Pizza Negroni.jpg"
            },
            {
                "id": 106,
                "enName": "NEGRONI BIANCO",
                "roName": "NEGRONI BIANCO",
                "enDescription": "Gin Opera, Petroni Bianco Vermouth, Suze, elderflower (120 ml)",
                "roDescription": "Gin Opera, Vermouth Petroni alb, Suze, soc (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/White Negroni9.jpg"
            },
            {
                "id": 107,
                "enName": "NEGRONI SBAGLIATO",
                "roName": "NEGRONI SBAGLIATO",
                "enDescription": "Petroni Rojo Vermouth, Campari, Prosecco (160 ml)",
                "roDescription": "Vermouth Petroni rosu, Campari, Prosecco (160 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Signature cocktails",
        roSubCategory: "Signature cocktails",
        dishes: [
            {
                "id": 108,
                "enName": "KYOTO",
                "roName": "KYOTO",
                "enDescription": "Sake, Talisker 10 year old, Petroni Rojo Vermouth, Sauvignon Blanc, elderflower (120 ml)",
                "roDescription": "Sake, Talisker 10 yo, Vermouth Petroni rojo, Vin sauvignon blanc, soc (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 109,
                "enName": "SICILY MULE",
                "roName": "SICILY MULE",
                "enDescription": "Aperol, strawberries, blood oranges, ginger beer (160 ml)",
                "roDescription": "Aperol, capsune, portocale roșii, bere de ghimbir (160 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Sicily mulle2.jpg"
            },
            {
                "id": 110,
                "enName": "TOMATO SPRITZ",
                "roName": "TOMATO SPRITZ",
                "enDescription": "Malfy Limone Gin, tomato water, tomato shrub, 3 Cents tonic water (160 ml)",
                "roDescription": "Gin Malfy limone, apa de tomate, shrub de tomate, apa tonica 3 cents (160 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/TOMATO SPRITZ 5.jpg"
            },
            {
                "id": 111,
                "enName": "SAGE PALOMA",
                "roName": "SAGE PALOMA",
                "enDescription": "Reposado Tequila, sage, lime, 3 Cents grapefruit soda (160 ml)",
                "roDescription": "Tequila reposado, salvie, lime, 3 cents grapefruit soda (160 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 112,
                "enName": "CHRONICLE",
                "roName": "CHRONICLE",
                "enDescription": "Vermouth, palo santo, cold brew, lemon, chamomile (120 ml)",
                "roDescription": "Vermouth, palo santo, cold brew, lămâie, musetel (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Chronicl.jpg"
            },
            {
                "id": 113,
                "enName": "DISARONNO SOUR",
                "roName": "DISARONNO SOUR",
                "enDescription": "Disaronno, Italicus, lemon, foam (120 ml)",
                "roDescription": "Disaronno, Italicus, lămâie, foamee (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Disaronno souer 7.jpg"
            },
            {
                "id": 114,
                "enName": "ESPRESSO MARTINI",
                "roName": "ESPRESSO MARTINI",
                "enDescription": "Vodka, coffee, tonka beans, vanilla, chili (120 ml)",
                "roDescription": "Vodka, cafea, tonka, vanilie, chili (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": "/img/preparate/Espresso Martini.jpg"
            },
            {
                "id": 115,
                "enName": "THE GODFATHER",
                "roName": "THE GODFATHER",
                "enDescription": "Bourbon, Disaronno, coconut, Angostura bitters (120 ml)",
                "roDescription": "Bourbon, Disaronno, cocos, bitter Angoustura (120 ml)",
                "price": "36 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Brunch cocktails",
        roSubCategory: "Brunch cocktails",
        dishes: [
            {
                "id": 116,
                "enName": "BREAKFAST MARTINI",
                "roName": "BREAKFAST MARTINI",
                "enDescription": "Malfy Lemon Gin, orange marmalade, orange liqueur, lemon (120 ml)",
                "roDescription": "Gin Malfy lemon, marmelada de portocale, lichior de portocale, lămâie (120 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 117,
                "enName": "MIMOSA",
                "roName": "MIMOSA",
                "enDescription": "Prosecco, fresh orange juice (120 ml)",
                "roDescription": "Prosecco, fresh de portocale (120 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 118,
                "enName": "BELLINI",
                "roName": "BELLINI",
                "enDescription": "Prosecco, peaches (120 ml)",
                "roDescription": "Prosecco , piersici (120 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 119,
                "enName": "ROSSINI",
                "roName": "ROSSINI",
                "enDescription": "Prosecco, strawberries (120 ml)",
                "roDescription": "Prosecco, capsune (120 ml)",
                "price": "34 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Digestif & aperitif",
        roSubCategory: "Digestif & aperitif",
        dishes: [
            {
                "id": 1201,
                "enName": "Bacardi",
                "roName": "Bacardi",
                "enDescription": "(40 ml)",
                "roDescription": "",
                "price": "26 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1202,
                "enName": "Hine",
                "roName": "Hine",
                "enDescription": "(40 ml)",
                "roDescription": "",
                "price": "28 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1203,
                "enName": "Bulleit",
                "roName": "Bulleit",
                "enDescription": "(40 ml)",
                "roDescription": "",
                "price": "24 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1204,
                "enName": "Disaronno",
                "roName": "Disaronno",
                "enDescription": "(40 ml)",
                "roDescription": "",
                "price": "18 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1205,
                "enName": "Frangelico",
                "roName": "Frangelico",
                "enDescription": "(40 ml)",
                "roDescription": "",
                "price": "18 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 120,
                "enName": "LIMONCELLO",
                "roName": "LIMONCELLO",
                "enDescription": "",
                "roDescription": "",
                "price": "19 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 121,
                "enName": "AVERNA AMARO",
                "roName": "AVERNA AMARO",
                "enDescription": "",
                "roDescription": "",
                "price": "19 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 122,
                "enName": "FERNET BRANCA",
                "roName": "FERNET BRANCA",
                "enDescription": "",
                "roDescription": "",
                "price": "19 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 123,
                "enName": "GRAPPA NONINO",
                "roName": "GRAPPA NONINO",
                "enDescription": "",
                "roDescription": "",
                "price": "24 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 124,
                "enName": "SAMBUCA ROMANA",
                "roName": "SAMBUCA ROMANA",
                "enDescription": "",
                "roDescription": "",
                "price": "19 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 125,
                "enName": "PALINCA DE CAISE ILIE GALBEN",
                "roName": "PALINCA DE CAISE ILIE GALBEN",
                "enDescription": "",
                "roDescription": "",
                "price": "29 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Beer",
        roSubCategory: "Bere",
        dishes: [
            {
                "id": 126,
                "enName": "BUCUR",
                "roName": "BUCUR",
                "enDescription": "Bere blonda ( 350ml )",
                "roDescription": "Bere blonda ( 350ml )",
                "price": "20 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 127,
                "enName": "BUCUR",
                "roName": "BUCUR",
                "enDescription": "Bere bruna ( 350ml )",
                "roDescription": "Bere bruna ( 350ml )",
                "price": "20 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 128,
                "enName": "KRONENBOURG BLANC",
                "roName": "KRONENBOURG BLANC",
                "enDescription": "( 330ml )",
                "roDescription": "( 330ml )",
                "price": "20 lei", // Add the price for Kronenbourg Blanc 
                "nutrition": "",
                "image": ""
            },
            {
                "id": 129,
                "enName": "CALSBERG FARA ALCOOL",
                "roName": "CALSBERG FARA ALCOOL",
                "enDescription": "( 330ml )",
                "roDescription": "( 330ml )",
                "price": "19 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Water & soft drinks",
        roSubCategory: "Water & soft drinks",
        dishes: [
            {
                "id": 130,
                "enName": "APA MINERALA SAN PELLEGRINO",
                "roName": "APA MINERALA SAN PELLEGRINO",
                "enDescription": "",
                "roDescription": "",
                "price": "22 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 131,
                "enName": "APA PLATA AQUA PANNA",
                "roName": "APA PLATA AQUA PANNA",
                "enDescription": "",
                "roDescription": "",
                "price": "22 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 132,
                "enName": "SAN PELLEGRINO ARANCIATTA ROSA",
                "roName": "SAN PELLEGRINO ARANCIATTA ROSA",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 133,
                "enName": "SAN PELLEGRINO LIMONATA",
                "roName": "SAN PELLEGRINO LIMONATA",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 134,
                "enName": "SAN PELLEGRINO POMEGRANATE",
                "roName": "SAN PELLEGRINO POMEGRANATE",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 135,
                "enName": "SAN PELLEGRINO POMPELO",
                "roName": "SAN PELLEGRINO POMPELO",
                "enDescription": "",
                "roDescription": "",
                "price": "16 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 136,
                "enName": "COCA COLA",
                "roName": "COCA COLA",
                "enDescription": "",
                "roDescription": "",
                "price": "12 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 137,
                "enName": "FRESH DE PORTOCALE / GRAPEFRUIT",
                "roName": "FRESH DE PORTOCALE / GRAPEFRUIT",
                "enDescription": "",
                "roDescription": "",
                "price": "20 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Cafea & Ceai",
        roSubCategory: "Cafea & Ceai",
        dishes: [
            {
                "id": 138,
                "enName": "ESPRESSO SIMPLU / DUBLU",
                "roName": "ESPRESSO SIMPLU / DUBLU",
                "enDescription": "",
                "roDescription": "",
                "price": "10 RON / 14 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 139,
                "enName": "ESPRESSO MACHIATTO",
                "roName": "ESPRESSO MACHIATTO",
                "enDescription": "",
                "roDescription": "",
                "price": "12 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 140,
                "enName": "FLAT WHITE",
                "roName": "FLAT WHITE",
                "enDescription": "",
                "roDescription": "",
                "price": "19 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 141,
                "enName": "CAFFE LATTE",
                "roName": "CAFFE LATTE",
                "enDescription": "",
                "roDescription": "",
                "price": "17 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 142,
                "enName": "CAPPUCINNO",
                "roName": "CAPPUCINNO",
                "enDescription": "",
                "roDescription": "",
                "price": "15 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 143,
                "enName": "CEAI",
                "roName": "CEAI",
                "enDescription": "Ceai de fructe / menta / musetel / rooibos vanilla / iasomie",
                "roDescription": "Ceai de fructe / menta / musetel / rooibos vanilla / iasomie",
                "price": "16 RON",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Bar",
        roCategory: "Bar",
        enSubCategory: "Ice tea & lemonade",
        roSubCategory: "Ice tea & lemonade",
        dishes: [
            {
                "id": 144,
                "enName": "PEACH ICE TEA",
                "roName": "PEACH ICE TEA",
                "enDescription": "Peaches, black tea, mint, lemon",
                "roDescription": "Piersici, ceai negru, menta, lămâie",
                "price": "18 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 145,
                "enName": "SOCATA",
                "roName": "SOCATA",
                "enDescription": "Fresh lemon, elderflower, sugar, mineral water",
                "roDescription": "Fresh de lămâie, soc, zahar, apa minerala",
                "price": "20 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 146,
                "enName": "ORANJADA",
                "roName": "ORANJADA",
                "enDescription": "Fresh orange, fresh lemon, sugar, mineral water",
                "roDescription": "Fresh de portocale, fresh de lămâie, zahar, apa minerala",
                "price": "20 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 147,
                "enName": "LIMONADA CU PIERSICI",
                "roName": "LIMONADA CU PIERSICI",
                "enDescription": "Fresh lemon, peaches, sugar, sparkling water",
                "roDescription": "Fresh de lămâie, piersici, zahar, apa plata",
                "price": "20 RON",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 148,
                "enName": "LIMONADA CU MENTA",
                "roName": "LIMONADA CU MENTA",
                "enDescription": "Fresh lemon, mint, sugar, sparkling water",
                "roDescription": "Fresh de lămâie, menta, zahar, apa plata",
                "price": "20 RON",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Wine",
        roCategory: "Vin",
        enSubCategory: "White wine",
        roSubCategory: "Vin alb",
        dishes: [
            {
                "id": 149,
                "enName": "TENUTA ULISSE PECORINO",
                "roName": "TENUTA ULISSE PECORINO",
                "enDescription": "Pecorino (150 ml / 750 ml)",
                "roDescription": "Pecorino (150 ml / 750 ml)",
                "price": "28 lei / 140 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 150,
                "enName": "DI LENARDO SAUVIGNON BLANC",
                "roName": "DI LENARDO SAUVIGNON BLANC",
                "enDescription": "Sauvignon Blanc (750 ml)",
                "roDescription": "Sauvignon Blanc (750 ml)",
                "price": "150 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 151,
                "enName": "TENUTA ULISSE VENDEMMIE BIANCO",
                "roName": "TENUTA ULISSE VENDEMMIE BIANCO",
                "enDescription": "Pecorino (750 ml)",
                "roDescription": "Pecorino (750 ml)",
                "price": "220 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 152,
                "enName": "LA PRUINA MALVASIA BIANCA",
                "roName": "LA PRUINA MALVASIA BIANCA",
                "enDescription": "Malvasia Bianca (150 ml / 750 ml)",
                "roDescription": "Malvasia Bianca (750 ml)",
                "price": "24 lei / 140 lei",
                "nutrition": "",
                "image": ""
            },

            {
                "id": 1153,
                "enName": "TRAMIN",
                "roName": "TRAMIN",
                "enDescription": "PEPI Sauvignon 2022 Alto Adige DOC (750 ml)",
                "roDescription": "PEPI Sauvignon 2022 Alto Adige DOC (750 ml)",
                "price": "180 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1156,
                "enName": "TENUTA ULISSE",
                "roName": "TENUTA ULISSE",
                "enDescription": "Pinot Grigio ( 750 ml)",
                "roDescription": "Pinot Grigio ( 750 ml)",
                "price": "145 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1155,
                "enName": "SOGNO DI ULISSE CHARDONAY MALVASIA",
                "roName": "SOGNO DI ULISSE CHARDONAY MALVASIA",
                "enDescription": "(150 ml / 750 ml)",
                "roDescription": "(150 ml / 750 ml)",
                "price": "24 lei / 120 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1157,
                "enName": "TRAMIN  PEPI",
                "roName": "TRAMIN  PEPI",
                "enDescription": "Sauvignon 2022 Alto Adige DOC (750 ml)",
                "roDescription": "Sauvignon 2022 Alto Adige DOC (750 ml)",
                "price": "180 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Wine",
        roCategory: "Vin",
        enSubCategory: "Rose wine",
        roSubCategory: "Vin rose",
        dishes: [
            {
                "id": 153,
                "enName": "CECILIA BERETTA CHIARETTO DI BARDOLINO",
                "roName": "CECILIA BERETTA CHIARETTO DI BARDOLINO",
                "enDescription": "(150 ml / 750 ml)",
                "roDescription": "(150 ml / 750 ml)",
                "price": "27 lei / 130 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 154,
                "enName": "PASSOFINO ROSATO",
                "roName": "PASSOFINO ROSATO",
                "enDescription": "Merlot, Montepulciano (150 ml / 750 ml)",
                "roDescription": "Merlot, Montepulciano (150 ml / 750 ml)",
                "price": "24 lei / 120 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 155,
                "enName": "DI LENARDO GOSIP PINOT GRIGIO",
                "roName": "DI LENARDO GOSIP PINOT GRIGIO",
                "enDescription": "Pinot Grigio (750 ml)",
                "roDescription": "Pinot Grigio (750 ml)",
                "price": "150 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Wine",
        roCategory: "Vin",
        enSubCategory: "Red wine",
        roSubCategory: "Vin rosu",
        dishes: [
            {
                "id": 156,
                "enName": "PRUINA NEGROAMARO",
                "roName": "PRUINA NEGROAMARO",
                "enDescription": "Negroamaro ( 150 ml / 750 ml )",
                "roDescription": "Negroamaro ( 150 ml / 750 ml )",
                "price": "29 lei / 140 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 157,
                "enName": "CASTIGLION DEL BOSCO BRUNELLO DI MONTALCINO DOCG",
                "roName": "CASTIGLION DEL BOSCO BRUNELLO DI MONTALCINO DOCG",
                "enDescription": "Sangiovesse ( 750 ml )",
                "roDescription": "Sangiovesse ( 750 ml )",
                "price": "520 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 158,
                "enName": "LA PRUINA ILMO PRIMITIVO DI MANDURIA",
                "roName": "LA PRUINA ILMO PRIMITIVO DI MANDURIA",
                "enDescription": "Primitivo di Manduria ( 750 ml )",
                "roDescription": "Primitivo di Manduria ( 750 ml )",
                "price": "220 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 1152,
                "enName": "La PRUINA PRIMITIVO",
                "roName": "La PRUINA PRIMITIVO",
                "enDescription": "(150 ml)",
                "roDescription": "(150 ml)",
                "price": "24 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 159,
                "enName": "TENUTA ULISSE AMARANTA",
                "roName": "TENUTA ULISSE AMARANTA",
                "enDescription": "Montepulciano ( 750 ml)",
                "roDescription": "Montepulciano ( 750 ml)",
                "price": "180 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
    {
        enCategory: "Wine",
        roCategory: "Vin",
        enSubCategory: "Sparkling wine / Champaing",
        roSubCategory: "Vin spumant / Sampanie",
        dishes: [
            {
                "id": 160,
                "enName": "BERNARDINO PROSECCO SUPERIORE DOCG",
                "roName": "BERNARDINO PROSECCO SUPERIORE DOCG",
                "enDescription": "Glera ( 150 ml / 750 ml )",
                "roDescription": "Glera ( 150 ml / 750 ml )",
                "price": "27 lei / 140 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 161,
                "enName": "NINO FRANCO PROSECCO SUPERIORE DOCG",
                "roName": "NINO FRANCO PROSECCO SUPERIORE DOCG",
                "enDescription": "Glera ( 150 ml / 750 ml )",
                "roDescription": "Glera ( 150 ml / 750 ml )",
                "price": "27 lei / 140 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 162,
                "enName": "FRANCIACORTA BELLAVISTA",
                "roName": "FRANCIACORTA BELLAVISTA",
                "enDescription": "Chardonnay ( 750 ml )",
                "roDescription": "Chardonnay ( 750 ml )",
                "price": "280 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 163,
                "enName": "CARASTELEL CARASSIA BLANC DE BLANC",
                "roName": "CARASTELEL CARASSIA BLANC DE BLANC",
                "enDescription": "Chardonnay ( 750 ml )",
                "roDescription": "Chardonnay ( 750 ml )",
                "price": "180 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 164,
                "enName": "LA MONTINA FRANCIACORTA SATEN",
                "roName": "LA MONTINA FRANCIACORTA SATEN",
                "enDescription": "Franciacorta ( 750 ml )",
                "roDescription": "Franciacorta ( 750 ml )",
                "price": "190 lei",
                "nutrition": "",
                "image": ""
            },
            {
                "id": 165,
                "enName": "LA MONTINA FRANCIACORTA ROSE",
                "roName": "LA MONTINA FRANCIACORTA ROSE",
                "enDescription": "Franciacorta ( 750 ml )",
                "roDescription": "Franciacorta ( 750 ml )",
                "price": "190 lei",
                "nutrition": "",
                "image": ""
            }
        ]
    },
];

export default menuData;