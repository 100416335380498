import React from 'react';

export const TwemojiFlagRomania = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path fill="#002B7F" d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z"></path>
        <path fill="#FCD116" d="M12 5h12v26H12z"></path>
        <path fill="#CE1126" d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4z"></path>
    </svg>
)

export default TwemojiFlagRomania;


