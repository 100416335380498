import React from 'react';

const DishIndicators = ({ dishes, currentDishIndex, setCurrentDishIndex }) => {
    return (
        <div className="absolute bottom-0 flex items-end justify-center w-full pb-4 transform -translate-x-1/2 left-1/2">
            {dishes.map((dish, index) => (
                <div
                    key={dish.id}
                    className={`mx-1 rounded-full ${currentDishIndex === index ? 'bg-perfetto-lemon' : 'bg-perfetto-olive'} w-3 h-3`}
                    onClick={() => setCurrentDishIndex(index)}
                >
                </div>
            ))}
        </div>
    );
};

export default DishIndicators;


