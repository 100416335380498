import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import BurgerMenu from './BurgerMenu'
import menuData from './menuData';
import TwemojiFlagRomania from './TwemojiFlagRomania'
import TwemojiFlagUnitedKingdom from './TwemojiFlagUnitedKingdom'
import DishIndicators from './DishIndicators';
import MaterialSymbolsLightKeyboardBackspaceRounded from './MaterialSymbolsLightKeyboardBackspaceRounded'

const App = () => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentDishIndex, setCurrentDishIndex] = useState(0);
  const [showCategoryName, setShowCategoryName] = useState(false);
  const [language, setLanguage] = useState('ro');
  const [showDishList, setShowDishList] = useState(true);  // Default to true to show list upon category selection


  const preloadImages = (srcArray) => {
    srcArray.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    preloadImages([
      '/img/Left-corner.png',
      '/img/Right-corner.png'
    ]);
  }, []);


  const handleCategorySelect = (index) => {
    if (index !== currentCategoryIndex) {
      setCurrentCategoryIndex(index);
      setCurrentDishIndex(0); // Reset to the first dish of the new category
      setShowDishList(true); // Show dish list upon category change
      setShowCategoryName(true); // Start showing category name animation
      setTimeout(() => setShowCategoryName(false), 1500); // End category name animation
    }
  };

  const handleDishSelect = (index) => {
    setCurrentDishIndex(index);
    setShowDishList(false); // Hide the dish list after selecting a dish
  };

  const DishList = ({ dishes }) => (
    <div className="inset-0 flex flex-col items-center justify-center w-screen bg-perfetto-lut h-svh">
      <ul className="items-center w-full max-w-md p-4 text-center">
        {dishes.map((dish, index) => (
          <li key={dish.id} onClick={() => handleDishSelect(index)} className="p-1 border-t cursor-pointer border-perfetto-olive first-of-type:border-t-0 hover:bg-gray-100 font-perfetto">
            {language === 'en' ? dish.enName : dish.roName}
          </li>
        ))}
      </ul>
    </div>
  );

  const toggleLanguage = () => {
    setLanguage(lang => lang === 'en' ? 'ro' : 'en');
  };

  // Handlers for swipe actions
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setCurrentDishIndex(prev => prev < menuData[currentCategoryIndex].dishes.length - 1 ? prev + 1 : prev);
    },
    onSwipedRight: () => {
      setCurrentDishIndex(prev => prev > 0 ? prev - 1 : prev);
    },
    onSwipedDown: () => {
      if (currentCategoryIndex > 0) {
        setShowDishList(true);
        setShowCategoryName(true);
        setTimeout(() => setShowCategoryName(false), 1500); // Hide after 1 second
        setCurrentCategoryIndex(prev => {
          if (prev > 0) {
            setCurrentDishIndex(0); // Reset dish index when changing category
            return prev - 1;
          }
          return prev;
        })
      }
    },
    onSwipedUp: () => {
      if (currentCategoryIndex < menuData.length - 1) {
        setShowDishList(true);
        setShowCategoryName(true);
        setTimeout(() => setShowCategoryName(false), 1500); // Hide after 1 second
        setCurrentCategoryIndex(prev => {
          if (prev < menuData.length - 1) {
            setCurrentDishIndex(0); // Reset dish index when changing category
            return prev + 1;
          }
          return prev;
        })
      }
    },
    trackMouse: true
  });

  return (
    <div {...handlers} className="absolute top-0 left-0 w-full overflow-hidden h-svh bg-perfetto-lut text-perfetto-olive">

      <div className="flex flex-col h-full transition-transform ease-in-out duration-0" style={{ transform: `translateY(${-currentCategoryIndex * 100}svh)` }}>
        <div>

        </div>
        {menuData.map((category, idx) => (
          <div key={idx} className="flex flex-col items-start justify-center w-full h-svh">
            {showCategoryName && currentCategoryIndex === idx ? (
              <div className="relative flex flex-col items-center justify-center w-screen h-svh">
                <h2 className={`text-4xl  text-center font-perfetto ${showCategoryName ? 'fade-in' : 'fade-out'}`}>{language === 'en' ? category.enCategory : category.roCategory}</h2>
                <section className="flex flex-row w-2/3 p-6">
                  <div className="w-1/3 border-t-2 border-green-600"></div>
                  <div className="w-1/3 border-t-2 border-white"></div>
                  <div className="w-1/3 border-t-2 border-red-600"></div>
                </section>
                <h2 className={`text-2xl  text-center ${showCategoryName ? 'fade-in' : 'fade-out'}`}>{language === 'en' ? category.enSubCategory : category.roSubCategory}</h2>
                <img src='/img/Left-corner.png' alt='Lemon Left Perfetto' className='absolute top-0 left-0 w-2/3'></img>
                <img src='/img/Right-corner.png' alt='Lemon Left Perfetto' className='absolute bottom-0 right-0 w-2/3'></img>
              </div>
            ) : (

              <div className="relative flex h-full overflow-hidden snap-mandatory snap-x">



                <div className='absolute top-0 z-20 w-screen'>
                  <button onClick={toggleLanguage} className='absolute w-6 pt-2 pr-4 right-4'>
                    {language === 'en' ? <TwemojiFlagRomania style={{ width: 'auto', height: '25px' }} /> : <TwemojiFlagUnitedKingdom style={{ width: 'auto', height: '25px' }} />}
                  </button>
                </div>

                <div className='absolute top-0 flex justify-center w-screen pt-2 pr-4 font-semibold font-perfetto'>

                  {(language === 'en' ? category.enSubCategory : category.roSubCategory).length > 1 ? (
                    <span className='pl-2 pt-[4px] text-sm align-bottom justify-end items-baseline'>{language === 'en' ? ' ' + category.enSubCategory : ' ' + category.roSubCategory}</span>
                  ) : (
                    <>
                      <h2>{language === 'en' ? category.enCategory : category.roCategory} </h2>
                    </>
                  )}
                </div>

                {showDishList ? <BurgerMenu className="absolute top-0 w-screen" categories={menuData} onCategorySelect={handleCategorySelect} language={language} /> : <div className='absolute top-0 left-0 z-10 flex flex-row items-center w-auto pt-1.5 pl-4 cursor-pointer' onClick={() => setShowDishList(true)}>
                  <MaterialSymbolsLightKeyboardBackspaceRounded />
                </div>}

                {showDishList ? <DishList dishes={category.dishes} /> : (
                  <div className="flex transition-transform duration-500 ease-in-out " style={{ transform: `translateX(${-currentDishIndex * 100}vw)` }}>
                    {menuData[currentCategoryIndex].dishes.map((dish, index) => (
                      <div key={dish.id} className="relative flex flex-col items-center flex-none w-screen gap-5 px-4 pt-10 pb-4 h-svh snap-center">
                        {dish.image && dish.image.length > 1 && (
                          <div className='items-center w-full max-h-[50%]'>
                            <img
                              className={`w-full h-full object-cover rounded-2xl ${currentDishIndex === index ? 'bd-fadeup-down-1' : ''}`}
                              src={dish.image}
                              alt={dish.name}                        // Re-trigger animation
                            />
                          </div>
                        )}
                        <div className='h-1/2'>
                          <div className={`pt-4 h-2/3 items-center flex flex-col justify-between ${currentDishIndex === index ? 'bd-fadeup-down-2' : ''}`}>
                            <h3 className={`text-2xl text-center font-perfetto`}>{language === 'en' ? dish.enName : dish.roName}</h3>
                            <p className="text-base text-center ">{language === 'en' ? dish.enDescription : dish.roDescription}</p>

                            <span className='text-xl font-perfetto'>{dish.price}</span>
                          </div>

                        </div>
                      </div>
                    ))}

                  </div>
                )}



                {showDishList ? (<></>) : (
                  <div className='absolute bottom-0 w-screen'>
                    <section className="flex flex-row w-full">
                      <div className="w-1/3 border-t-4 border-green-600"></div>
                      <div className="w-1/3 border-t-4 border-white"></div>
                      <div className="w-1/3 border-t-4 border-red-600"></div>
                    </section>

                    <DishIndicators dishes={category.dishes} currentDishIndex={currentDishIndex} setCurrentDishIndex={setCurrentDishIndex} />

                  </div>
                )}

              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


export default App;