import React from 'react';

export const MaterialSymbolsLightKeyboardBackspaceRounded = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" d="m5.915 12.5l3.747 3.746q.14.14.15.344q.01.204-.15.364t-.354.16q-.195 0-.354-.16l-4.389-4.389q-.13-.13-.183-.267q-.053-.136-.053-.298t.053-.298q.053-.137.183-.267l4.389-4.389q.14-.14.344-.15q.204-.01.364.15t.16.354t-.16.354L5.915 11.5H19.5q.213 0 .357.143q.143.144.143.357t-.143.357q-.144.143-.357.143H5.915Z"></path>
    </svg>
)

export default MaterialSymbolsLightKeyboardBackspaceRounded;


