import React, { useState } from 'react';

export const HeroiconsBars3BottomLeftSolid = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd"></path>
    </svg>
)

const BurgerMenu = ({ categories, onCategorySelect, language, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <div className={`${className}`}>
            <button className="z-40 rounded burger-icon bg-perfetto-olive text-perfetto-lemon top-2 left-4" onClick={toggleMenu}>
                <HeroiconsBars3BottomLeftSolid />  {/* Unicode for burger icon */}
            </button>
            <div className={`menu ${isOpen ? "open" : ""} absolute h-screen z-30 font-perfetto text-base overflow-scroll items-center`}>
                <ul>
                    {categories.map((category, index) => (
                        <li key={index} onClick={() => {
                            onCategorySelect(index);  // Call the passed function with the index
                            toggleMenu();  // Optionally close the menu
                        }}>


                            {(language === 'en' ? category.enSubCategory : category.roSubCategory).length > 1 ? (
                                <>
                                    {language === 'en' ? category.enSubCategory : category.roSubCategory}
                                    <span className='text-sm'> - ( {language === 'en' ? category.enCategory : category.roCategory} )</span>
                                </>
                            ) : (
                                <>
                                    {language === 'en' ? category.enCategory : category.roCategory}
                                </>
                            )}


                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default BurgerMenu;


